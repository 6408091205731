import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router';

export const createCorrelationId = uuidv4;

export const catchPromise = async ( promise ) => {
  try {
      let result = await promise;
      return [result, null];
  } catch(error) {
      return [null, error];
  }
}


export function useQuery() {
  let location = useLocation();
  return new URLSearchParams(location.search);
}


export class BasicErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children; 
  }
}
