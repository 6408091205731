import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { createCorrelationId } from "utils";
import { Link } from "react-router-dom";

import PageLayout from 'components/PageLayout';
import { UsersTable } from 'pages/Users';
import { ApiContext } from "lib/api";

export default function UserPage() {
  let { id } = useParams();
  let { api } = useContext(ApiContext);
  let [user, setUser] = useState(null);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      let [result, error] = await api.getUser({
        id,
        correlationId: createCorrelationId(),
      });
      setLoading(false);
      if (result) {
        setUser(result.user || []);
      }
    })();
  }, []);

  if (loading || user === null) {
    return <div className="user-page">Loading</div>;
  }
  return (
    <PageLayout>
        <div className="user-page">
            <h1>User ({id})</h1>
            <p>
                <Link to="/users">← Users</Link>
            </p>
            <UsersTable users={[user]} />
            <UserProfile profile={user?.profile} />
        </div>
    </PageLayout>
  );
}

function ObjectDump({ obj }) {
  return <div className="object-dump">{JSON.stringify(obj, null, 2)}</div>;
}

function UserProfile({ profile }) {
  return (
    <div className="user-profile">
      <ProfileStagesSection profile={profile} />
      <ProfileDataSection profile={profile} />
    </div>
  );
}

function ProfileStagesSection({ profile }) {
  return (
    <div className="profile-stages-section">
      <h2>Tasks completed</h2>
      <ProfileStagesTable profile={profile} />
    </div>
  );
}

function ProfileDataSection({ profile }) {
  return (
    <div className="profile-data-section">
      {Object.keys(profile?.data || {}).map((dataName) => {
        return (
          <div className="profile-data-wrap">
            <h2>{dataName}</h2>
            <ObjectDump obj={profile?.data?.[dataName]} />
          </div>
        );
      })}
    </div>
  );
}

function ProfileStagesTable({ profile }) {
  return (
    <div className="profile-stages-table">
      <table className="table">
        <thead>
          <tr>
            <th>Stage</th>
            <th>Task</th>
            <th>Marked as completed</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(profile?.stages || {}).map((stageName) => {
            let stage = profile?.stages?.[stageName];
            let taskNames = Object.keys(stage?.tasks || {});
            let stageRowspan = taskNames.length;
            return taskNames.map((taskName, ix) => {
              let task = stage?.tasks?.[taskName];
              return (
                <tr>
                  {ix === 0 ? (
                    <td rowSpan={stageRowspan}>{stageName}</td>
                  ) : null}
                  <td>{taskName}</td>
                  <td>{task.markedAsCompleted ? "Yes" : "No"}</td>
                </tr>
              );
            });
          })}
        </tbody>
      </table>
    </div>
  );
}
