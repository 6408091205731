import "./App.css";
import { useContext, useEffect, useState } from "react";
import { ApiContext } from "./lib/api";
import { BasicErrorBoundary } from "./utils";
import { Route, Switch } from "react-router-dom";
import ReportsPage from './pages/Reports';
import UsersPage from './pages/Users';
import UserPage from './pages/User';

function App() {
  let apiContext = useContext(ApiContext);
  let [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    let { api } = apiContext;
    console.log("Api has changed");
    setLoggedIn(api.isLoggedIn());
  }, [apiContext, setLoggedIn]);
  if (!loggedIn) {
    return <AuthRouter />
  } else {
    return (
      <Switch>
        <Route exact path="/reports">
          <ReportsPage />
        </Route>
        <Route exact path={["", "/", "/users"]}>
          <UsersPage />
        </Route>
        <Route path="/users/:id">
          <UserPage />
        </Route>
      </Switch>
    );
  }
}
export default App;

function AuthRouter(){
  return (
    <Switch>
      <Route path='/register'>
        <RequestRegistrationPage/>
      </Route>
      <Route>
        <LoginPage />;
      </Route>
    </Switch>
  )
}


const defaultRequestRegistrationCredentials = (
  process.env.NODE_ENV === 'development' ?
  {
    email: "alex@kettel.io",
    password: "password"
  } : 
  {
    email: '',
    password: ''
  }
);

function RequestRegistrationPage(){
  let { api } = useContext(ApiContext);
  let [ result, setResult ] = useState();
  let [credentials, setCredentials] = useState({...defaultRequestRegistrationCredentials});
  const onChangeFieldFactory = (field) => (event) => {
    setCredentials({ ...credentials, [field]: event.target.value });
  };
  const onSubmit = (event) => {
    event.preventDefault();
    api
      .requestRegistration({ ...credentials })
      .then(setResult)
      .catch(setResult);
  };
  return <div className='registration-page'>
    <h1>Request registration</h1>
    <div>
      <form onSubmit={onSubmit}>
      <div className="input-wrap">
          <p>Email</p>
          <p>
            <input
              type="email"
              value={credentials.email}
              onChange={onChangeFieldFactory("email")}
            />
          </p>
        </div>
        <div className="input-wrap">
          <p>Password</p>
          <p>
            <input
              type="password"
              value={credentials.password}
              onChange={onChangeFieldFactory("password")}
            />
          </p>
        </div>
        <div className="submit-wrap">
          <button type="submit">Request registration</button>
        </div>
      </form>
    </div>
    <div>
      <BasicErrorBoundary>
        <p>{ (result instanceof Error) ? result.message : JSON.stringify(result) }</p>
      </BasicErrorBoundary>
    </div>
  </div>
}



const defaultLoginCredentials = (
  process.env.NODE_ENV === 'development' ?
  {
    email: "admin@example.com",
    password: "password"
  } : 
  {
    email: '',
    password: ''
  }
);
function LoginPage() {
  let { api } = useContext(ApiContext);
  let [credentials, setCredentials] = useState({...defaultLoginCredentials});
  const onChangeFieldFactory = (field) => (event) => {
    setCredentials({ ...credentials, [field]: event.target.value });
  };
  const onSubmit = (event) => {
    event.preventDefault();
    api
      .login({ ...credentials })
      .then(console.log)
      .catch(console.error);
  };
  return (
    <div className="login-page">
      <h1>Log in</h1> 
      <form onSubmit={onSubmit}>
        <div className="input-wrap">
          <p>Email</p>
          <p>
            <input
              type="email"
              value={credentials.email}
              onChange={onChangeFieldFactory("email")}
            />
          </p>
        </div>
        <div className="input-wrap">
          <p>Password</p>
          <p>
            <input
              type="password"
              value={credentials.password}
              onChange={onChangeFieldFactory("password")}
            />
          </p>
        </div>
        <div className="submit-wrap">
          <button type="submit">Log in</button>
        </div>
      </form>
    </div>
  );
}

