import { useCallback, useContext } from "react";
import PageLayout from 'components/PageLayout';
import { ApiContext, Pagination } from "lib/api";

export default function ReportsPage(){
    let { api } = useContext(ApiContext);
    let downloadUserActionReport = useCallback(() => {
        api.getUserPropertyViewsReport({})
    }, [api]);
    const downloadAllUsersTaskReport = useCallback(() => {
        let pagination = new Pagination({ 
            pageNumber: 1, 
            pageSize: -1 
        })
        api.getUserTasksReport({ pagination });
    }, []);
        
    return <PageLayout>
        <div className='ReportsPage'>
            <h1>Reports</h1>
            <ul>
                <li>
                    <button onClick={downloadUserActionReport}>
                        Download user action report
                    </button>
                </li>
                <li>
                    <button onClick={downloadAllUsersTaskReport}>
                        Download all users task report
                    </button>
                </li>
            </ul>
        </div>
    </PageLayout>
}