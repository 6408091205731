import { createContext, useEffect, useState } from 'react';
import config from '../config';
import { catchPromise } from '../utils';
import EventEmitter from 'events';

const ApiEvents = {
    LOGGED_IN: 'LOGGED_IN'
}

class Api extends EventEmitter {
    constructor(apiRootUrl){
        super();
        this.apiRootUrl = apiRootUrl;
        this.token = undefined;
        this.forceRerender = () => {};
    }

    static addCorrelationIdToRequest(request, correlationId){
        request.headers.set('x-correlation-id', correlationId);
    }

    prepareRequest(path, method, params, init, correlationId){
        init = init || {};
        let url = new URL(`${this.apiRootUrl}${path}`);
        Object.keys(params || {}).forEach(param => {
            url.searchParams.set(param, params[param]);
        });

        // JSON-ify the request
        if ( init.body ){
            init.body = JSON.stringify(init.body);
        }
        
        let request = new Request(url, { method, ...init });
        if ( init.body ){
            request.headers.set('content-type', 'application/json');
        }
        Api.addCorrelationIdToRequest(request, correlationId);
        if ( this.token ) {
            request.headers.set('Authorization', this.token);
        }
        return request;
    }

    async genericJsonRequest(path, method, params, init, correlationId){
        let request = this.prepareRequest(path, method, params, init, correlationId)
        try {
            console.log('[API] Requesting')
            console.table({ url: path, params: JSON.stringify(params) })
            let response = await fetch(request);
            let [json, jsonError] = await catchPromise(response.json());
            if ( response.status !== 200 ) {
                if ( jsonError ) {
                    return [ null, 'UnknownApiError' ]
                } else if ( json.error ) {
                    return [ null, json.error ]
                } else {
                    return [ null, 'UnknownApiError' ]
                }
            }
            return [ json, null ];
        } catch ( error ) {
            // TODO: capture and report this error
            console.error(error)
            // TODO: subclass this error for easier handling later
            return [ null, 'NetworkError' ]
        }
    }

    async csvRequest(path, method, params, init, correlationId){
        let request = this.prepareRequest(path, method, params, init, correlationId)
        try {
            console.log('[API] Requesting')
            console.table({ url: path, params: JSON.stringify(params) })
            let response = await fetch(request);
            let body = await response.text();
            let csvData = new Blob([body], { type: 'text/csv' }); 
            let csvUrl = URL.createObjectURL(csvData);
            (() => {
                let link = document.createElement("a");
                link.href = csvUrl;
                link.style = "visibility:hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })()
            
        } catch ( error ) {
            console.error(error)
        }
    }

    isLoggedIn(){
        return this.token !== undefined;
    }

    async login({email, password, correlationId}) {
        let [ result, error ] = await this.genericJsonRequest('/login', 'POST', null, { body: { email, password }}, correlationId);
        try {
            this.token = result.token;
            this.emit(ApiEvents.LOGGED_IN)
        } catch (error) {
            console.error(error)
        }
        return [result, error];
    }

    async requestRegistration({ email, password, correlationId }){
        return await this.genericJsonRequest('/request-registration', 'POST', null, { body: { email, password }}, correlationId);
    }

    async getUsers({ pagination, correlationId }){
        pagination = pagination || new Pagination({ pageNumber: 1, pageSize: 25 });
        return this.genericJsonRequest('/api/users', 'GET', pagination.toObj(), null, correlationId)
    }

    async getUser({ id, correlationId }){
        return this.genericJsonRequest(`/api/users/${id}`, 'GET', null, null, correlationId)
    }

    async getUserTasksReport({ pagination, correlationId }){
        pagination = pagination || new Pagination({ 
            pageNumber: 1, 
            pageSize: 25 
        });
        return this.csvRequest('/api/reports/user-tasks', 'GET', pagination.toObj(), null, correlationId)
    }

    async getUserPropertyViewsReport({}){
        return this.csvRequest('/api/reports/property-views', 'GET', null, null, null)
    }
}


const api = new Api(config.apiRootUrl);
export const ApiContext = createContext({ api });

export const GlobalApiContext = ({ children }) => {
    let [ state, setState ] = useState({ api });

    const refreshState = () => {
        const newState = {...state};
        setState(newState)
    }
    useEffect(() => {
        api.addListener(ApiEvents.LOGGED_IN, refreshState);
    }, [])
    
    return <ApiContext.Provider value={state}>
        { children }
    </ApiContext.Provider>
}

export class Pagination {
    constructor({ pageNumber, pageSize }){
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
    }

    nextPage(){
        return new Pagination({
            pageNumber: this.pageNumber + 1,
            pageSize: this.pageSize
        })
    }

    previousPage(){
        return new Pagination({
            pageNumber: Math.max(this.pageNumber - 1, 1),
            pageSize: this.pageSize
        })
    }

    toObj() {
        return {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize
        }
    }
    
    toQueryString(){
        let params = new URLSearchParams();
        params.set('pageNumber', this.pageNumber);
        params.set('pageSize', this.pageSize);
        return params.toString();
    }
}


export class PaginatedUserSearch{
    constructor({api, pageSize=25, pageNumber=1}){
        this.api = api;
        this.pagination = new Pagination({ pageSize, pageNumber });
    }

    /***
     * Returns:
     * [users[], error]
     */
    async request({ pagination }){
        pagination = pagination || this.pagination;
        console.log('Requesting users:', pagination)
        let [ result, error ] = await this.api.getUsers({ pagination });
        if ( result ) {
            let { pageNumber, pageSize } = result.pagination;
            this.pagination.pageSize = pageSize;
            this.pagination.pageNumber = pageNumber;
            return [ result.users, null ]
        }
        console.error(error);
        return [ null, error ]
    }

    async nextPage(){
        let pagination = this.pagination.nextPage();
        return await this.request({ pagination });
    }
    async previousPage(){
        let pagination = this.pagination.previousPage();
        return await this.request({ pagination });
    }
}