import { ReactComponent as Logo } from "assets/logo.svg"
import { Link } from 'react-router-dom';
import './index.scss';

export function Header(){
    return <header className='Header'>
        <div className='header-logo-wrap'>
            <Logo/>
        </div>
        <nav>
            <ul>
                <li>
                    <Link to='/'>
                        Users
                    </Link>
                </li>
                <li>
                    <Link to='/reports'>
                        Reports
                    </Link>
                </li>
            </ul>
        </nav>
    </header>
}


export default function PageLayout({ children }){
    return <div className='PageLayout'>
        <Header/>
        <main>
            { children }
        </main>
    </div>
}