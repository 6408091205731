import { useContext, useState, useCallback, useEffect } from "react";
import { ApiContext, PaginatedUserSearch } from "lib/api";
import { Link } from "react-router-dom";

import PageLayout from "components/PageLayout";
import "./index.scss";

export default function UsersPage() {
  let { api } = useContext(ApiContext);
  let [searchState, setSearchState] = useState({
    search: new PaginatedUserSearch({ api }),
  });
  let [users, setUsers] = useState([]);
  let [loading, setLoading] = useState(true);

  const handleRequest = useCallback(
    (promise) => {
      promise.then(([users, error]) => {
        if (users) setUsers(users);
        setLoading(false);
      });
    },
    [setUsers]
  );

  useEffect(() => {
    searchState.search.api = api;
    setSearchState({ search: searchState.search });
  }, [api]);

  useEffect(() => {
    handleRequest(searchState.search.request({}));
  }, []);

  const nextPage = useCallback(() => {
    handleRequest(searchState.search.nextPage());
  }, [handleRequest, searchState]);

  const previousPage = useCallback(() => {
    handleRequest(searchState.search.previousPage());
  }, [handleRequest, searchState]);

  const downloadUserTasksReport = useCallback(() => {
    let pagination = searchState.search.pagination;
    api.getUserTasksReport({ pagination }).then(console.log);
  }, [searchState]);

  return (
    <PageLayout>
      <div className="users-page">
        <h1>Users</h1>
        <UserTableControlsHeader>
          <PaginationControls>
            <p>Page number: {searchState.search.pagination.pageNumber}</p>
            <p>
              <button onClick={nextPage}>Next page →</button>
            </p>
            <p>
              <button onClick={previousPage}>← Previous page</button>
            </p>
          </PaginationControls>
          <p>
            <button onClick={downloadUserTasksReport}>
              Download user tasks report
            </button>
          </p>
        </UserTableControlsHeader>
        {loading ? "Loading" : <UsersTable users={users} />}
      </div>
    </PageLayout>
  );
}

function PaginationControls({ children }) {
  return <div className="PaginationControls">{children}</div>;
}

function UserTableControlsHeader({ children }) {
  return <div className="UserTableControlsHeader">{children}</div>;
}

export function UsersTable({ users }) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Id</th>
          <th>First name</th>
          <th>Last name</th>
          <th>Email</th>
          <th>Link</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          return <UserRow user={user} />;
        })}
      </tbody>
    </table>
  );
}

function UserRow({ user }) {
  return (
    <tr key={user.id}>
      <td>{user.id}</td>
      <td>{user.first_name}</td>
      <td>{user.last_name}</td>
      <td>{user.email}</td>
      <td>
        <Link to={`/users/${user.id}`}>link</Link>
      </td>
    </tr>
  );
}
